html {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#extole_zone_earnings_page a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
}

#extole_zone_job_page a {
  text-decoration: none;
  margin-left: 8;
}
#extole_zone_job_page > div {
  display: flex;
  cursor: pointer;
}

#extole_zone_global_header a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
}
